import { getPage } from "@10xdev/cms/resolvers/get-page";
import PageLayout from "@10xdev/ui/src/PageLayout";
import type { GetStaticProps, NextPage } from "next";

import { stripUndefineds } from "../../utils";

interface Props {
  data: any;
}

const ChromiumConnect: NextPage<Props> = ({ data }) => (
  <PageLayout data={data} />
);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const data = await getPage("in-situ-technology", locale!);

  return {
    props: {
      data: stripUndefineds(data),
    },
  };
};

export default ChromiumConnect;
